.LoadPageText {
    font-size: calc(8px + 1vmin);
}

.Art-div {
    width: 90%;
    margin: auto;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: 169px;
    grid-auto-flow: dense;
}

.Art-img {
    cursor: pointer;
    max-width: 100%;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: inline-block;
    object-fit: cover;
  }

.Art-col {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.img-wide {
    grid-column: span 2;
 }
  
.img-big {
    grid-column: span 2;
    grid-row: span 2;
}
  
.img-tall {
    grid-row: span 2;
}

.Slider-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
}

.Slider-item-image {
    height: 730px;
    width: 1050px;
    margin: auto;
}

img::selection {
    background: transparent;
}
  
.Slider-img {
    height: 100%;
    width: 100%;
    max-width: fit-content;
}

.Slider-item-previous {
    width: 50%;
    height: 43%;
    position: absolute;
    left: 0;
}
  
.Slider-icon {
    height: 100%;
    cursor: pointer;
    color: grey;
}
  
.Slider-icon:hover {
    color: white;
}
  
.Slider-item-next {
    width: 50%;
    height: 43%;
    position: absolute;
    right: 0;
}

.Slider-back-btn {
    color: grey !important;
    text-decoration: none !important;
    font-size: calc(6px + 1vmin) !important;
}
  
.Slider-back-btn:hover {
    color: white !important;
}

@media screen and (min-width: 1400px) {
    .container {
      max-width: 75% !important;
    }
    .Art-div {
      grid-template-columns: repeat(auto-fit, minmax(101px, 1fr));
      grid-auto-rows: 161px;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .Slider-item-image {
      height: 650px;
      width: max-content;
    }
  }
  
  @media screen and (max-width: 924px) {
    .offcanvas-body {
      padding: 0 15px !important;
    }
    .Slider-item-image {
      height: auto;
    }
    .Slider-img {
      max-height: 650px;
      max-width: fit-content;
    }
    .Slider-item-previous{
      text-align: left;
      left: 65px;
      height: 350px;
    }
    .Slider-item-next{
      text-align: right;
      right: 65px;
      height: 350px;
    }
    .Slider-icon {
      width: 30px;
    }
    .LoadPageText {
      font-size: calc(8px + 1vmin);
    }
    .Slider-back-btn {
      font-size: calc(10px + 1vmin) !important;
    }
  }
  
  @media screen and (max-width: 600px) {
    .Slider-item-image {
      height: auto;
    }
    .Slider-img {
      max-height: 480px;
      max-width: 300px;
    }
    .Slider-item-previous{
      text-align: left;
      height: 200px;
      left: 0;
    }
    .Slider-item-next{
      text-align: right;
      right: 3px;
      height: 200px;
    }
    .Slider-icon {
      width: 25px;
    }
    .Art-div {
      width: 81%;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(71px, 1fr));
      grid-auto-rows: 128px;
    }
    .LoadPageText {
      font-size: calc(10px + 1vmin);
    }
    .Slider-back-btn {
      font-size: calc(10px + 1vmin) !important;
      padding: 21px 0 !important;
    }
  }
  
  @media screen and (min-width:320px) and (max-width:915px) and (orientation: landscape) {
    .container {
      max-width: 100% !important;
    }
    .Art-div {
      width: 88%;
      grid-gap: 15px;
      grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
      grid-auto-rows: 149px;
    }
    .Slider-item-image {
      max-width: fit-content;
      height: 300px;
    }
    .Slider-icon {
      width: 20px;
    }
    .Slider-item-previous{
      height: 120px;
      left: 161px;
      width: 30%;
    }
    .Slider-item-next{
      height: 120px;
      right: 161px;
      width: 30%;
    }
  }