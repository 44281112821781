.LandingPage-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Slider-items {
    display: flex;
  	flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
}

img::selection {
    background: transparent;
}
  
.Slider-img {
    height: 100%;
    width: 100%;
    max-width: fit-content;
}
  
.Slider-item-previous {
    width: 50%;
    height: 43%;
    position: absolute;
    left: 0;
}
  
.Slider-icon {
    height: 100%;
    cursor: pointer;
    color: grey;
}
  
.Slider-icon:hover {
    color: white;
}
  
.Slider-item-next {
    width: 50%;
    height: 43%;
    position: absolute;
    right: 0;
}
  
.Slider-item-image {
    height: 730px;
    width: 1050px;
    margin: auto;
}

@media screen and (min-width: 1400px) {
    .container {
      max-width: 75% !important;
    }
}
  
@media screen and (max-width: 1200px) {
    .Slider-item-image {
      height: 650px;
      width: max-content;
    }
}
  
@media screen and (max-width: 924px) {
    .offcanvas-body {
      padding: 0 15px !important;
    }
    .Slider-item-image {
      height: auto;
    }
    .Slider-img {
      max-height: 650px;
      max-width: fit-content;
    }
    .Slider-item-previous{
      text-align: left;
      left: 65px;
      height: 350px;
    }
    .Slider-item-next{
      text-align: right;
      right: 65px;
      height: 350px;
    }
    .Slider-icon {
      width: 30px;
    }
}
  
@media screen and (max-width: 600px) {
    .Slider-item-image {
      height: auto;
    }
    .Slider-img {
      max-height: 480px;
      max-width: 300px;
    }
    .Slider-item-previous{
      text-align: left;
      height: 200px;
      left: 0;
    }
    .Slider-item-next{
      text-align: right;
      right: 3px;
      height: 200px;
    }
    .Slider-icon {
      width: 25px;
    }
}
  
@media screen and (min-width:320px) and (max-width:915px) and (orientation: landscape) {
    .container {
      max-width: 100% !important;
    }
    .LandingPage-body {
    display: block;
    }
    .Slider-item-image {
      max-width: fit-content;
      height: 300px;
    }
    .Slider-icon {
      width: 20px;
    }
    .Slider-item-previous{
      height: 120px;
      left: 161px;
      width: 30%;
    }
    .Slider-item-next{
      height: 120px;
      right: 161px;
      width: 30%;
    }
}