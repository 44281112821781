.App {
  text-align: center;
  background-color: black;
}

body {
  background-color: black !important;
}

.App-body {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
}

.App-navbar {
    display: flex;
    margin: 0;
    top: 0;
    justify-content: left;
    position: fixed;
    color: white;
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 75% !important;
  }
}

@media screen and (max-width: 924px) {
  .offcanvas-body {
    padding: 0 15px !important;
  }
}

@media screen and (min-width:320px) and (max-width:915px) and (orientation: landscape) {
  .container {
    max-width: 100% !important;
  }
}